.@{class-prefix}-page-title {
  margin: 0 32px;
}

.@{class-prefix}-btn-page-title {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  min-width: 40px;
}

.@{class-prefix}-topbar {
  height: 56px;
  padding: 16px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  display: none;
}

.@{class-prefix}-content-show-banner {
  .@{class-prefix}-topbar {
    top: 64px !important;
  }
}

.@{class-prefix}-topbar-title {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
}

.@{class-prefix}-topbar-title {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
}

@media @lg {
  .@{class-prefix}-page-title {
    display: none;
  }

  .@{class-prefix}-topbar {
    display: flex !important;
  }

  .@{class-prefix}-app-banner{
    display: inline-flex !important;
  }
}

.@{class-prefix}-app-banner {
  height: 64px;
  padding: 8px;
  position: fixed;
  display: none;
  top: 0;
  width: 100%;
  z-index: 101;
  border-radius: 0;
}