.@{class-prefix}-bottom-sheet{
    .ant-drawer-header{
        padding-top: 32px;
    }
    .ant-drawer-content{
        border-radius: 24px 24px 0px 0px;

    }

    .ant-drawer-body{
        padding-bottom: 64px;
    }

}
