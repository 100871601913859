.ant-form-item-label {
  text-align: left !important;
}

.ant-form-item-label > label::after {
  content: "" !important;
}

.ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border: none;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: @primary-4;
  color: @primary-1;
  border: 1px solid @primary-1 !important;
}

.hp-calendar .fc-timegrid-event .fc-event-main {
  padding: 4px;
}

// Input Remove Required & Showing Optional
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "*" !important;
}

.ant-form-item-label > label .ant-form-item-required::after {
  content: "*" !important;
  color: @danger-1 !important;
}

.ant-form-item-label > label:not(.ant-form-item-required)::after {
  display: inline-block !important;
  content: "(opsional)" !important;
  color: @b-60;
}

.ant-card{
  border: none;
}

body.dark {
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: @dark-100;
  }
}

.ant-form-item-label {
  text-align: left !important;
}

.ant-form-item-label > label::after {
  content: "" !important;
}

.hp-calendar .fc-timegrid-event .fc-event-main {
  padding: 4px;
}

.ant-input-number-affix-wrapper {
  background: none !important;
}

.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip {
  color: @primary-1;
}

// Input Remove Required & Showing Optional
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: "" !important;
}

.ant-form-item-label > label:not(.ant-form-item-required)::after {
  display: inline-block !important;
  content: "(opsional)" !important;
  color: @b-60;
}

.@{class-prefix}-tabs-full {
  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-tab {
    display: block; /* centers text inside tabs */
    flex: 1;
    text-align: center;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
  }
}

.@{class-prefix}-tabs-solid .ant-tabs-nav-list {
  background: @b-0;
}

.@{class-prefix}-dashboard-carousel{
  height: 450px;
}

.ant-input-group-addon{
  font-size: 14px;
}

.ant-input-number-group-addon{
  font-size: 14px;
}

// ====== CUSTOM DARK MODE ====

body.dark {
  small {
    color: @b-0;
  }
  .ant-card{
    color: @dark-0 !important;
  }
  .ant-input-number-affix-wrapper {
    background: none !important;
    color: @b-0 !important;
    border-color: @dark-80;
  }


 .ant-input-group-addon{
  background: none !important;
  color: @b-0 !important;
  border-color: @dark-80;
 }

  .ant-input-number-group-addon {
    background: none !important;
    color: @b-0 !important;
    border-color: @dark-80;
  }

  .ant-table-cell-row-hover {
    background: @dark-90 !important;
  }
  .row-dragging {
    background: @dark-90 !important;
    border: 1px solid @dark-90;
  }

  .ant-popover-arrow-content::before {
    background: @dark-100;
  }

  .@{class-prefix}-tabs-solid .ant-tabs-nav-list {
    background: @dark-90;
  }
  .ant-input-prefix {
    color: @b-0;
  }
  
  .ant-input-suffix {
    color: @b-0;
  }
}

.@{class-prefix}-app-menu{
  h6{
    font-size: 14px;
    font-weight: 500;
  }
}

.@{class-prefix}-home-menu{
  display: none;

}

@media @lg{
  .@{class-prefix}-home-menu{
    display: flex;
  }
}