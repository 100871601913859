.@{class-prefix}-content-main {
  margin: 0 32px;
  min-height: calc(100vh - 134px);

  @media @sm {
    margin: 24px 16px 0;
    min-height: calc(100vh - 134px);
    margin: 0 16px;
  }
}

@media @lg {
  .@{class-prefix}-content-main {
    min-height: calc(100vh - 130px);
    margin-top: 80px;
    padding-bottom: 80px;
  }

  .@{class-prefix}-content-show-banner{
    margin-top: 80px;
  }

  .@{class-prefix}-content-main-full {
    min-height: calc(100vh - 56px);
    margin-top: 80px;
    padding-bottom: 80px;
  }
}
