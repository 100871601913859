.@{class-prefix}-switch-data-list{
    .ant-radio-button-wrapper{
        border: none;
        background: none;
        color: @grey-1;
        font-size: 20px;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
        box-shadow: none;
    }
    .ant-radio-button-wrapper:not(:first-child)::before{
        height: 50%;
        top: 15%;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before{
        background-color: @grey-1 !important;
    }

    .ant-radio-button-wrapper-checked{
        border: none;
        background: none;
        color: @primary-1;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
        border-color:@grey-2;
    }
}